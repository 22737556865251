<template>
  <div style="margin: -30px">
    <div class="course-details-wrapper topic-1 uk-light pt-5">
      <div class="container p-sm-0">
        <div uk-grid>
          <div class="uk-width-2-3@m" style="margin-left: 27rem">
            <div class="d-flex">
              <nav id="breadcrumbs" class="mb-3">
                <ul>
                  <li>
                    <router-link :to="{ name: 'company.dashboard' }">
                      <i class="uil-home-alt"></i>
                    </router-link>
                  </li>
                  <li class="font-weight-light">Katalog Eğitimleri</li>
                  <li class="font-weight-light">
                    {{ item.training_type ? item.training_type.name : "" }}
                  </li>
                  <li class="font-weight-light">{{ item.name }}</li>
                </ul>
              </nav>
            </div>
            <div class="course-details">

              <div class="uk-alert-success bg-success-700 h-25px" uk-alert v-if="successMessageSend">
                <a class="uk-alert-close" uk-close></a>
                <p style="color: green">
                  <i class="fa fa-check d-inline-flex p-2" aria-hidden="true"></i>{{ successMessageSend }}
                </p>
              </div>

              <div class="uk-alert-success position-relative" uk-alert v-if="successMessage != null">
                <a class="uk-alert-close" uk-close></a>
                <p style="color: green" class="m-0">
                  <i class="fa fa-check d-inline-flex mr-2" aria-hidden="true"></i>{{ successMessage }}
                </p>
              </div>
              <div class="uk-alert-danger bg-soft-danger position_relative" uk-alert v-if="errorMessage != null">
                <a class="uk-alert-close" uk-close></a>
                <p style="color: red" class="m-0">{{ errorMessage }}</p>
              </div>
              <span>{{
                item.training_type ? item.training_type.name : ""
              }}</span>
              <h1>{{ item.name }}</h1>
              <!-- <div class="course-details-info mt-4">
                <ul>
                  <li>
                    <i class="icon-feather-flag"></i>
                    {{ $t("education.Start_date") }}: {{ item.start_date }}
                  </li>
                  <li>
                    <i class="icon-feather-calendar"></i>
                    {{ $t("education.End_date") }}:{{ item.end_date }}
                  </li>
                </ul>
              </div> -->
              <div class="course-details-info">
                <ul>
                  <li>
                    <i class="icon-feather-users"></i>
                    {{ item.users_completed_count }}
                    {{ $t("education.Person_Completed_Training") }}
                  </li>
                </ul>
              </div>
            </div>
            <nav class="responsive-tab style-5">
              <ul id="education_detail_switcher_tab"
                uk-switcher="connect: #education_detail_switcher_list ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium">
                <li>
                  <a class="font-weight-light" href="#"><i class="uil-list-ul"></i>
                    {{ $t("education.descriptions") }}</a>
                </li>
                <li>
                  <a class="font-weight-light" href="#"><i class="uil-list-ul"></i> Satınalma Taleplerim</a>
                </li>
                <li>
                  <a class="font-weight-light" href="#"><i class="uil-comment-info-alt"></i>
                    {{ $t("education.Sharing_Experience") }}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="uk-grid-large mt-4" uk-grid>
        <div class="uk-width-2-3@m">
          <ul id="education_detail_switcher_list" class="uk-switcher mt-4">
            <!-- Contents -->
            <li>
              <h4>{{ $t("education.about") }}</h4>
              <p class="mb-4">{{ item.description }}</p>
              <div uk-margin class="mb-4">
                <a v-for="(item, index) in item.hashtag" href="javascript:void(0)" class="btn btn-small btn-light"
                  :key="index">#{{ item.name }}</a>
              </div>
              <div class="uk-alert-success bg-gray-700 h-25px" uk-alert v-if="successMessage">
                <a class="uk-alert-close" uk-close></a>
                <p style="color: green">
                  <i class="fa fa-check d-inline-flex p-2" aria-hidden="true"></i>{{ successMessage }}
                </p>
              </div>

              <ul class="course-curriculum" uk-accordion="multiple: true">
                <li :class="index === 0 ? 'uk-open' : ''" v-for="(subItem, index) in item.training_section" :key="index">
                  <a class="uk-accordion-title" href="#"> {{ subItem.name }}</a>
                  <div class="uk-accordion-content">
                    <ul class="course-curriculum-list">
                      <template v-for="(
                          trainingContent, subIndex
                        ) in subItem.training_content">

                        <ContentItem :key="'item_' + subIndex"
                          @linkClick="showContentModal(trainingContent, trainingContent.completed_by_user)"
                          v-if="trainingContent.file_category" :icon="trainingContent.file_category.icon"
                          :is_completed="false" :is_unlocked="true" :is_locked="false" :is_demo="trainingContent.is_demo"
                          :name="$t(trainingContent.file_category.key_name)" :title="trainingContent.name"
                          :point="trainingContent.score"></ContentItem>
                      </template>
                    </ul>
                  </div>
                </li>
              </ul>
              <div id="admin_content_modal" uk-modal esc-close="true" bg-close="true" class="p-0"
                style="z-index: 9999999; background: rgba(0, 0, 0, 1)">
                <div class="uk-modal-dialog bg-transparent" v-if="selected_content != null" style="width: 100%">
                  <button class="uk-modal-close-default text-white mt-1 mr-3" type="button" uk-close></button>
                  <div class="uk-modal-header bg-transparent border-0 px-5">
                    <h2 class="uk-modal-title text-white">
                      {{ selected_content.name }}
                    </h2>
                  </div>
                  <div class="uk-modal-body py-0" uk-overflow-auto>
                    <div class="video-responsive" v-if="selected_content.training_file_type_id == 2">
                      <img :src="selected_content.file.file_link" />
                    </div>
                    <div class="video-responsive" v-if="selected_content.training_file_type_id == 6">
                      <audio controls="" :src="selected_content.file.file_link"></audio>
                    </div>
                    <div class="video-responsive" v-if="selected_content.training_file_type_id == 7">
                      <template>
                        <div class="artplayer-app"></div>
                      </template>
                    </div>
                    <div class="iframe-responsive" v-if="selected_content.training_file_type_id == 8">
                      <iframe width="100vw" height="100vh" :src="selected_content.training_content"
                        :title="selected_content.title" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                      </iframe>
                    </div>
                    <div class="iframe-responsive" v-if="selected_content.training_file_type_id == 3">
                      <iframe width="100vw" height="100vh" :src="selected_content.file.file_link +
                        '#toolbar=0&navpanes=0&scrollbar=0'
                        " :title="selected_content.title" frameborder="0">
                      </iframe>
                    </div>
                    <div class="iframe-responsive" v-if="[9].includes(selected_content.training_file_type_id)
                      ">
                      <iframe width="100vw" height="100vh" :src="selected_content.link" :title="selected_content.title"
                        frameborder="0">
                      </iframe>
                    </div>
                    <div class="iframe-responsive" v-if="selected_content.training_file_type_id == 10">
                      <iframe width="100vw" height="100vh" :src="anzeraPackageItemUrl" :title="selected_content.title"
                        frameborder="0">
                      </iframe>
                    </div>
                    <div v-if="selected_content.training_file_type_id == 1" class="iframe-responsive">
                      <iframe :src="scormPackageItemUrl" :title="selected_content.title" frameborder="0" height="100vh"
                        width="100vw">
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <!-- Purchase Requests -->
            <li>
              <div class="d-flex align-items-baseline justify-content-between">
                <h4>Satınalma Taleplerim ({{ item.purchases ? item.purchases.length : 0 }})</h4>
                <h4>Kalan kullanıcı sayısı ({{ item.total_users_allowed }})</h4>
              </div>

              <div class="table-responsive">
                <table class="table align-items-center">
                  <thead>
                    <tr>
                      <th scope="col">Talep Tipi</th>
                      <th scope="col">Talep Tarihi</th>
                      <th scope="col">Durum</th>
                      <th scope="col">Onay Tarihi</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr v-for="(purchase, purchase_index) in item.purchases"
                      :key="'training_purchase_table_row_' + purchase_index">
                      <th scope="row">
                        <div class="media align-items-center">
                          <div class="media-body">
                            <p href="#" class="name h6 mb-0 text-sm">
                              {{ request_types[purchase.request_type_id] }} ({{
                                purchase.number_of_users
                              }}
                              kullanıcı)
                            </p>
                            <small class="d-block font-weight-bold">#{{ purchase.id }}</small>
                          </div>
                        </div>
                      </th>
                      <td>{{ toDate(purchase.created_at) }}</td>
                      <td :style="purchase.status_id == 2
                        ? 'color:green;font-weight: bold;'
                        : purchase.status_id == 0
                          ? 'color:indianred;font-weight: bold;'
                          : ''
                        ">
                        <i class="uil-clock-eight" v-if="purchase.status_id == 1"></i>
                        {{ getPurchaseStatus(purchase.status_id) }}
                      </td>
                      <td>{{ toDate(purchase.approved_at) }}</td>
                      <td class="text-right">
                        <!-- Actions -->
                        <div class="actions ml-3">
                          <a href="javascript:void(0)" v-if="purchase.status_id != 2"
                            class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Mesaj Gönder" title=""
                            aria-expanded="false" uk-toggle="target: #message_modal">
                            <i class="uil-envelope"></i>
                          </a>

                          <a href="javascript:void(0)" v-if="purchase.status_id == 1"
                            @click="removePurchase(purchase.id, purchase_index)"
                            class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Talebi İptal Et" title=""
                            aria-expanded="false">
                            <i class="uil-times-circle text-danger"></i>
                          </a>
                          <template v-if="purchase.status_id == 2">
                            <!-- <a href="javascript:void(0)"
                              v-if="(purchase.number_of_users - purchase.assigned_users_count) != 0"
                              class="btn btn-primary btn-hover btn-sm px-2 py-1" style="font-size: 0.6rem !important"
                              @click="selected_purchase = purchase" uk-toggle="target: #modal-overflow"><i
                                class="uil-location-arrow"></i>{{ $t('menu.company_dashboard.create_assignment') }}</a>
                            <a href="javascript:void(0)" v-else
                              class="btn btn-primary btn-hover btn-sm px-2 py-1 disabled"
                              style="font-size: 0.6rem !important"><i class="uil-location-arrow"></i>{{
                                $t('menu.company_dashboard.create_assignment') }}</a> -->
                          </template>
                          <div id="message_modal" class="uk-flex-top" uk-modal>
                            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                              <div class="uk-modal-header pl-0">
                                <h2 class="uk-modal-title">Yöneticiye Mesaj Gönder</h2>
                                <button class="uk-modal-close-default" type="button" uk-close></button>
                              </div>
                              <div class="uk-modal-body pl-0 pr-0">
                                <textarea name="content" id="content" class="form-control" v-model="form.content"
                                  placeholder="Mesajınız"></textarea>
                              </div>
                              <div class="uk-modal-footer pl-0 pr-0 d-flex justify-content-end">
                                <button class="uk-button uk-button-primary small" type="button"
                                  @click="saveForm(purchase.user_id)">
                                  <i class="icon-feather-arrow-right"></i> Gönder
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <!-- Feedbacks -->
            <li class="course-description-content">
              <div class="comments mt-4">
                <h4>
                  {{ this.$t('general.experience_sharing') }}
                  <span class="comments-amount">({{ item.approved_comments ? countCalculate(item.approved_comments) : 0 }}
                    {{ $t("education.Sharing") }})</span>
                </h4>
                <ul>
                  <template v-for="(comment, index) in item.approved_comments">
                    <Comments v-if="comment.user" :id="'comment_' + comment.id" :key="'comment_index_' + index"
                      :image="comment.user.photo_link" :title="comment.user.position.name"
                      :name="comment.user.name + ' ' + comment.user.surname" :comment="comment.text">
                    </Comments>
                  </template>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div class="uk-width-expand uk-flex-first">
          <div class="course-card-trailer" uk-sticky="top: 10 ; media: @m ; bottom:true">
            <div class="course-thumbnail">
              <img :src="item.cover_image_link" alt="" />
            </div>

            <div class="p-3">
              <div class="
                  uk-child-width-2-2 uk-grid-small
                  mb-4
                  uk-grid uk-grid-stack
                " uk-grid="">
                <div class="uk-first-column">
                  <div v-if="item.discount_codes" class="bg-gradient-danger shadow-danger uk-light mt-3 uk-alert"
                    uk-alert="">
                    <a class="uk-alert-close uk-icon uk-close" uk-close=""></a>
                    <p>{{ item.discount_codes }}</p>
                  </div>
                  <!-- <select class="selectpicker mt-3" v-model="request_number_type">
                    <option value="1">
                      {{ request_types[1] }}
                    </option>
                    <option value="2">{{ request_types[2] }}</option>
                  </select> -->
                </div>
                <div class="uk-grid-margin uk-first-column">
                  <a id="btn-requestlist" @click.prevent="showModalRequestListModal" href="#"
                    class="uk-width-1-1 btn btn-secondary transition-3d-hover">
                    <i class="uil-bell"></i> İstek Gönderenler Listesi ({{
                      item.users_pending_requests
                    }})
                  </a>
                  <div v-if="request_number_type == 2" @click="all_company_users_selected = !all_company_users_selected"
                    class="uk-width-1-1 btn btn-secondary mt-2 mb-0 d-flex"><input type="checkbox"
                      v-model="all_company_users_selected" class="h-auto w-auto m-0">
                    <p class="ml-2 mb-0">Tüm kullanıcıları seç ({{
                      item.company_users_count
                    }})</p>
                  </div>


                  <div id="message_modal_user" class="uk-flex-top" uk-modal>
                    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                      <div class="uk-modal-header pl-0">
                        <h2 class="uk-modal-title">Mesaj Gönder</h2>
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                      </div>
                      <div class="uk-modal-body pl-0 pr-0">
                        <textarea name="content" id="content_message" class="form-control" v-model="form.content"
                          placeholder="Mesajınız"></textarea>
                      </div>
                      <div class="uk-modal-footer pl-0 pr-0 d-flex justify-content-end">
                        <button class="uk-button uk-button-primary small" type="button" @click="sendMessage()">
                          <i class="icon-feather-arrow-right"></i> Gönder
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="modal-request-list" uk-modal="" class="uk-modal">
                    <div class="uk-modal-dialog">
                      <button class="uk-modal-close-default uk-icon uk-close" type="button" uk-close=""></button>
                      <div class="uk-modal-header">
                        <h2 class="uk-modal-title">İstek Yapan Kullanıcılar</h2>
                      </div>

                      <div class="uk-modal-body pb-5">
                        <div class="
                            uk-grid-small uk-flex-middle
                            position-relative
                            uk-grid
                          " uk-grid="" v-for="(request, index) in item.users_requests"
                          :key="'request_list_item_' + index">
                          <div class="uk-width-auto position-relative">
                            <img class="rounded-circle avatar-sm" :src="request.user.photo_link" />
                          </div>
                          <div class="uk-width-expand">
                            <p class="uk-text-truncate mb-0">
                              <b>{{
                                request.user.name + " " + request.user.surname
                              }}</b>
                            </p>
                            <p>
                              İstek Tarihi: {{ toDate(request.created_at) }}
                            </p>
                          </div>
                          <div>
                            <span class="btn-option userlist" aria-expanded="false">
                              <i class="icon-feather-more-vertical"></i>
                            </span>
                            <div class="dropdown-option-nav uk-dropdown" uk-dropdown="pos: bottom-right ;mode : hover">
                              <ul>
                                <li>
                                  <span class="cursor-pointer" @click="showUserModal(request.user)">
                                    <i class="uil-user"></i>
                                    Profili İncele
                                  </span>
                                </li>
                                <li>
                                  <span v-if="idCheck(request.user.id)" class="cursor-pointer"
                                    @click="showMessageSendModal(request.user.id)">
                                    <i class="uil-user"></i>
                                    Mesaj Gönder
                                  </span>
                                </li>
                                <!-- <li>
                                  <span class="cursor-pointer" @click="rejectRequest(request.id)">
                                    <i class="uil-user-times"></i>
                                    İstek Listesinden Kaldır</span>
                                </li> -->
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="
                      d-flex
                      align-items-center
                      mb-3
                      justify-content-between
                      mt-4
                    ">
                    <span style="font-size: 0.8rem" class="mr-1"><i class="uil-user"></i> Kişi Sayısı:</span>
                    <input id="user-count" style="width: 80px; height: 35px" type="number" class="m-0 pl-2 pr-0" min="1"
                      v-model="request_number"
                      :disabled="request_number_type == 1 || all_company_users_selected == true" />
                    <span class="uk-h5 m-0">
                      {{ getFinalItemPrice.toFixed(2) }} TL
                    </span>
                  </div> -->

                  <div>
                    <a href="javascript:void(0)" @click="purchase" :class="{
                      disabled: request_number === 0 || item.price === 0,
                    }" class="uk-width-1-1 btn btn-success transition-3d-hover">
                      <i class="uil-shopping-basket"></i> Satınalma Talebi
                      Gönder</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <UserProfile v-if="formattedUserDetail && activeItem" :item="activeItem" :user_detail="formattedUserDetail">
    </UserProfile>

    <AddToGroupModal :title="'Kullanıcı Ekle'" :existing_users="item.assigned_users"
      :allowed_number="selected_purchase ? (selected_purchase.number_of_users - selected_purchase.assigned_users_count) : 0"
      @getList="getList($event)"></AddToGroupModal>
  </div>
</template>

<script>
import Section from "@/view/components/educationDetail/Section";
import Tags from "@/view/components/search/Tags";
import Comments from "@/view/components/educationDetail/Comments";
import ContentItem from "@/view/components/assignmentDetail/ContentItem";
import moment from "moment";
import AddToGroupModal from "@/view/company/User/Group/AddToGroupModal";
import axios from "axios";
import UserProfile from "@/view/company/User/UserProfile";
import {
  GET_ITEM_DETAIL_BY_ID,
  UPDATE_ITEM_BY_ID,
  CREATE_ITEM,
  ERROR,
  DELETE_ITEM_BY_ID,
} from "@/core/services/store/REST.module";

import purchaseModule, {
  MODULE_NAME as PURCHASE_MODULE_NAME,
  GET_REQUEST_COUNT as GET_PURCHASE_USER_REQUEST_COUNT,
} from "@/core/services/store/training_purchase.module";

import user_detail_module, {
  GET_ITEM as GET_USER_DETAIL,
  ITEM as USER_DETAIL,
  MODULE_NAME as USER_DETAIL_MODULE
} from "@/core/services/store/user_detail.module";

const _PURCHASE_MODULE_NAME = PURCHASE_MODULE_NAME
const _USER_DETAIL_MODULE = USER_DETAIL_MODULE;

import store from "@/core/services/index";

export default {
  name: "RequestEducationDetails",
  components: {
    Section,
    Tags,
    Comments,
    ContentItem,
    AddToGroupModal,
    UserProfile
  },
  data() {
    return {
      activeItem: null,
      selectedUser: null,
      restUrl: "api/training/",
      apiURL: "api/user/self",
      trainingPurchaseUrl: "api/training/purchase/",
      trainingAssignUrl: "api/training/assign-users/",
      purchaseUrl: "api/training-purchase/",
      scormUrl: "api/training-content/get-scorm/",
      trainingRejectRequestUrl: "api/training/request/status/",
      scormPackageItemUrl: null,
      successMessage: null,
      successMessageSend: null,
      additional: [],
      item: [],
      form: {
        content: "",
      },
      selected_content: null,
      selected_purchase: [],
      errorMessage: null,
      request_number: 0,
      request_number_type: 1,
      all_company_users_selected: false,
      request_types: {
        // 1: "İstek gönderen kadar kullanıcı",
        1: "Tüm kullanıcılar",
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.secureLocalStorage("token"),
      }
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(_PURCHASE_MODULE_NAME, purchaseModule);
    registerStoreModule(_USER_DETAIL_MODULE, user_detail_module)

  },
  computed: {
    formattedUserDetail() {
      let isNull = _.isNaN(this.user_detail) || _.isEmpty(this.user_detail);
      if (isNull) return null;
      return this.user_detail;
    },
    getFinalItemPrice() {
      if (!this.request_number || this.request_number < 0) return 0;
      return (this.request_number * this.item.price) - ((this.item.price * parseInt(this.item.discount_percent ? this.item.discount_percent : 0) / 100) * this.request_number);
    },
    user_detail: {
      get() {
        return store.getters[USER_DETAIL_MODULE + '/' + USER_DETAIL]
      },
      set(value) {
      }
    },
    error: {
      get() {
        return this.$store.getters[ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    downloadURI(uri) {
      window.open(uri);
      this.successMessage = this.$t("general.content_download_successfully");
    },
    countCalculate(item) {
      let count = 0;
      item.forEach(element => {
        if (element.user) {
          count++;
        }
      });
      return count;
    },
    showContentModal(selected_content, is_completed) {
      this.isScorm = false;
      this.selected_content = selected_content;

      if ([4, 5].includes(selected_content.training_file_type_id)) {
        this.downloadURI(selected_content.file.file_link);
        return true;
      }

      if (selected_content.training_file_type_id === 1) {
        this.getScormEducationUrl(selected_content.id);
      }

      UIkit.modal("#admin_content_modal").show();
    },
    idCheck(user_id) {
      if (user_id != this.authUser.id) {
        return true
      } else {
        return false
      }
    },
    showUserModal(user) {
      this.activeItem = null;
      this.activeItem = user;
      this.getUserDetail(user.id);
    },
    showModalRequestListModal() {
      const modal2 = UIkit.modal("#modal-request-list");
      if (modal2) modal2.show();
    },
    showMessageSendModal(id) {
      const modal = UIkit.modal("#message_modal_user");
      if (modal) modal.show();
      this.selectedUser = id;
    },
    getUserDetail(id) {
      store.dispatch(USER_DETAIL_MODULE + '/' + GET_USER_DETAIL, {
        url: 'api/user/detail-admin' + '/' + id,
      })
    },
    saveForm(receiver_user_id) {
      let self = this;
      let form = self.form;
      let user_id = JSON.parse(this.secureLocalStorage("user")).id;
      let formData = new FormData();
      formData.append("message", form.content);
      formData.append("sender_user_id", user_id);

      let groupFormData = new FormData();
      groupFormData.append("users[]", [-1]);

      axios.post('api/store-group',
        groupFormData, {
        headers: self.headers
      })
        .then(function (response) {
          let id = response.data.id ?? response.data
          formData.append("message_group_id", id);
          axios.post('api/send-message',
            formData, {
            headers: self.headers
          })
            .then(function (response) {
              UIkit.modal('#message_modal').hide();
              self.successMessageSend = "Mesaj gönderildi";
            }).catch((error) => {
              self.errorMessage = response.data.message
            })
        })
    },
    getScormEducationUrl(content_id) {
      let self = this;
      let url = self.scormUrl + self.$route.params.id + "/training-content/" + content_id;
      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: url,
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            this.scormPackageItemUrl = result.data;
            return;
          }
        });
    },
    sendMessage() {
      let self = this;
      let form = self.form;
      let user_id = this.selectedUser;
      let formData = new FormData();
      formData.append("message", form.content);
      formData.append("sender_user_id", user_id);

      let groupFormData = new FormData();
      groupFormData.append("users[]", [user_id]);

      axios.post('api/store-group',
        groupFormData, {
        headers: self.headers
      })
        .then(function (response) {
          let id = response.data.id ?? response.data
          formData.append("message_group_id", id);
          axios.post('api/send-message',
            formData, {
            headers: self.headers
          })
            .then((response) => {
              UIkit.modal('#message_modal_user').hide();
              if (response.data.message) {
                self.errorMessage = response.data.message
              } else {
                self.successMessageSend = "Mesaj gönderildi";
              }
            })
        })
    },
    getTrainingDetails() {
      let self = this;
      let trainingId = self.$route.params.id;
      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: self.restUrl + trainingId,
          filters: { with_requests: true },
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            self.item = result.data;
            self.request_number = result.data.users_pending_requests;
          }
        });
    },
    purchase() {
      let self = this;
      this.request_number = 0;
      // if (self.request_number < 1) {
      //   this.errorMessage = this.$t("validation.required_field").replace(
      //     ":NAME",
      //     "Kişi Sayısı"
      //   );
      //   return false;
      // }

      let formData = new FormData();
      formData.append("request_type_id", self.request_number_type);
      // formData.append("number_of_users", self.request_number);
      self.$store
        .dispatch(CREATE_ITEM, {
          url: self.trainingPurchaseUrl + self.$route.params.id,
          contents: formData,
          acceptPromise: true,
        })
        .then((result) => {
          if (!self.error) {
            self.all_company_users_selected = false
            self.request_number_type = 1;
            self.$nextTick(function () { $('.selectpicker').selectpicker('refresh'); });
            self.switcherChange(1);
            self.item.purchases.unshift(result.data);
            self.getUserPurchaseRequestCount();
          } else self.errorMessage = self.error;
        });
    },
    getUserPurchaseRequestCount() {
      this.$store.dispatch(_PURCHASE_MODULE_NAME + "/" + GET_PURCHASE_USER_REQUEST_COUNT, {
        url: "api/purchase-request-count",
      });
    },
    removePurchase(id, index) {
      let self = this;
      self.$store
        .dispatch(DELETE_ITEM_BY_ID, {
          url: self.purchaseUrl + id,
          acceptPromise: true,
        })
        .then((result) => {
          if (!self.error) {
            self.item.purchases.splice(index, 1);
            self.getUserPurchaseRequestCount();
          } else self.errorMessage = self.error;
        });
    },
    rejectRequest(id) {
      let formData = new FormData();
      formData.append("request_id", id);
      formData.append("status_id", 0);
      this.$store
        .dispatch(UPDATE_ITEM_BY_ID, {
          url: this.trainingRejectRequestUrl + this.$route.params.id,
          contents: formData,
          acceptPromise: true,
        })
        .then((result) => {
          if (result.status) {
            this.item.users_requests = result.data;
            this.request_number--;
            this.item.users_pending_requests--;
          }
        });
    },
    getPurchaseStatus(id) {
      switch (id) {
        case 0:
          return "Onay verilmedi";
        case 1:
          return "Yanıt bekleniyor";
        case 2:
          return "Onay verildi";
        default:
          return "-";
      }
    },
    getList(value) {
      let self = this
      let users_count_left = self.selected_purchase.number_of_users - self.selected_purchase.assigned_users_count;
      if (value.length > users_count_left) {
        self.scrollTop()
        self.errorMessage = 'İzin verilen maksimum kullanıcı sayısı ' + users_count_left + '\'dır.'
        return false
      }

      let formData = new FormData();
      value.forEach(item => {
        formData.append("users[]", item.id);
      })
      formData.append("purchase_id", self.selected_purchase.id);
      self.$store
        .dispatch(CREATE_ITEM, {
          url: self.trainingAssignUrl + self.$route.params.id,
          contents: formData,
          acceptPromise: true,
        })
        .then((result) => {
          if (!self.error) {
            let data = result.data;
            self.selected_purchase.assigned_users_count = data.purchase_assigned_users_count
            self.item.total_users_allowed = data.total_users_allowed
            self.item.assigned_users = data.assigned_users
          } else self.errorMessage = self.error;
        });
    },
    scrollTop() {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    },
    toDate(date) {
      if (date == null || !date) return "-";
      return moment(date).format("DD-MM-YYYY HH:mm:ss");
    },
    resetMessages() {
      this.errorMessage = null;
      this.successMessage = null;
      this.successMessageSend = null;
    },
    switcherChange(index) {
      UIkit.switcher($("#education_detail_switcher_tab")[0]).show(index);
    },
  },
  watch: {
    formattedUserDetail(value) {
      const modal = UIkit.modal("#modal-profile");
      if (value) {
        if (modal) modal.show();
      }
    },
    request_number_type(val) {
      if (val == 1) this.request_number = this.item.users_pending_requests;
    },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessage() {
      this.debouncedResetValues();
    },
    successMessageSend() {
      this.debouncedResetValues();
    },
    all_company_users_selected(val, old) {
      if (old != val)
        this.request_number = val == true ? this.item.company_users_count : this.item.users_pending_requests;
    }
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 3000);
  },
  beforeDestroy() {
    const modal = UIkit.modal("#message_modal");
    if (modal) modal.$destroy(true);

    const modal2 = UIkit.modal("#message_modal_user");
    if (modal2) modal2.$destroy(true);
  },
  mounted() {
    $(".selectpicker").selectpicker();
    let self = this;
    let trainingId = self.$route.params.id;

    if (trainingId) {
      self.getTrainingDetails();
    } else {
      self.$router.go(-1);
    }
  },
};
</script>

<style >
#modal-request-list .uk-modal-body {
  min-height: 40vh;
  max-height: 80vh;
  overflow: scroll;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-gradient-danger {
  background: linear-gradient(50deg, #ff5630, #ff9430) !important;
}
</style>
